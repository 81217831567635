import store from '@/store';
import axios from 'axios';

export class API {
    constructor() {
        this.baseURL            = process.env.NODE_ENV === 'production' ? 'https://prod-rental-api.l5a.com/api/v1/central.php' : 'http://api.local.com/lfa/v1/central.php';
        this.withCredentials    = false;
        this.headers            = this._getHeaders();
        this.http               = this._getAxios();
        this.controller         = this._getController();
    }

    async get( endpoint ) {
        try {
            let res = await this.http.get( endpoint, {
                signal: this.controller.signal
            });
            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async post( endpoint, data = null, config = null ) {
        try {
            let res = await this.http.post( endpoint, data, config );
            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async put( endpoint, data = null ) {
        try {
            let res = await this.http.put( endpoint, data );
            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    cancelRequest(controller = null) {
        if( controller != null ) {
            controller.abort();
        }
    }

    cancel() {
        this.controller.abort();
    }

    _getHeaders() {
        let auth = '723868a5-48f6-11e9-ae2e-deadbe115600';
        if( store.getters['auth/isLoggedIn'] ) {
            auth += `,Bearer ${store.getters['auth/token']}` 
        }

        return {
            Authorization: auth
        };
    }

    _getAxios() {
        let http = axios.create({
            baseURL         : this.baseURL,
            withCredentials : this.withCredentials,
            headers         : this.headers,
            validateStatus(status) {
                if (status >= 200 && status < 300 && status !== 204) {
                    return true;
                } 
                else if( status == 403 ) {
                    return true;
                }
                
                return false;
            }
        });

        http.interceptors.response.use( function(response) {
            if( response.status === 401 && response.data.signout === true ) {
                store.dispatch('auth/logout');
            }

            if( response.data.token !== undefined && response.data.token.length > 0 ) {
                localStorage.setItem('lfa-central-auth', response.data.token);
                store.commit('auth/setToken', response.data.token);
            }

            return response;
        }, function(error) {
            if (error.response != undefined && error.response.status === 401 && error.response.data.signout === true) {
                store.dispatch('auth/logout');
            }

            return Promise.reject(error);
        });

        return http;
    }

    _getController() {
        return new AbortController();
    }
}